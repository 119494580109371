import Precision from "../assets/icons/precision_manufacturing_24dp_FILL1_wght300_GRAD0_opsz24.svg";
import Support from "../assets/icons/support_agent_24dp_FILL1_wght300_GRAD0_opsz24.svg";
import Architecture from "../assets/icons/architecture_24dp_FILL1_wght300_GRAD0_opsz24.svg";

import HealthAndSafety from "../assets/icons/health_and_safety_24dp_FILL1_wght300_GRAD0_opsz24.svg";
import Help from "../assets/icons/help_24dp_FILL1_wght300_GRAD0_opsz24.svg";
import Engineering from "../assets/icons/engineering_24dp_FILL1_wght300_GRAD0_opsz24.svg";

import Fire from "../assets/icons/local_fire_department_24dp_FILL1_wght300_GRAD0_opsz24.svg";
import Dual from "../assets/icons/mode_dual_24dp_FILL1_wght300_GRAD0_opsz24.svg";
import Report from "../assets/icons/report_24dp_FILL1_wght300_GRAD0_opsz24.svg";

import Stairs from "../assets/icons/stairs_24dp_FILL1_wght300_GRAD0_opsz24.svg";
import Tablet from "../assets/icons/tablet_24dp_FILL1_wght300_GRAD0_opsz24.svg";
import Task from "../assets/icons/task_24dp_FILL1_wght300_GRAD0_opsz24.svg";
import Warning from "../assets/icons/warning_24dp_FILL1_wght300_GRAD0_opsz24.svg";

import Info from "../assets/icons/info_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import Movement from "../assets/icons/radio_button_checked_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import Arrows from "../assets/icons/keyboard_double_arrow_up_24dp_FILL0_wght400_GRAD0_opsz24.svg";

export function IconSwitch(param: string | undefined) {
    switch (param) {
        case "Info":
            return Info;
        case "Movement":
            return Movement;
        case "Arrows":
            return Arrows;
        case "Precision":
            return Precision;
        case "Support":
            return Support;
        case "Architecture":
            return Architecture;
        case "Engineering":
            return Engineering;
        case "HealthAndSafety":
            return HealthAndSafety;
        case "Help":
            return Help;
        case "Fire":
            return Fire;
        case "Dual":
            return Dual;
        case "Report":
            return Report;
        case "Stairs":
            return Stairs;
        case "Tablet":
            return Tablet;
        case "Task":
            return Task;
        case "Warning":
            return Warning;
        default:
            return undefined;
    }
}
